<template>
  <div class="wrapper">
    <h1 class="title">
      <span class="crystal-ball">
        🔮
      </span>
      SimilarWeb Product Medium
    </h1>
    <div class="byline">
      Our advanced algorithm has peered into your future and found a most
      auspicious vision for the product:
    </div>
    <div class="generated-text">
      <div
        class="generated-text__inner"
      >
        <transition-group
          name="word-transition"
          mode="out-in"
        >
          <span
            :key="`${seedValue}-${n}`"
            v-for="(word, n) in generatedText.split(' ')"
            class="generated-text__word"
            :style="`transition-delay: ${0.2*(n+1)}s`"
          >
            {{ word }}
          </span>
        </transition-group>
      </div>
    </div>

    <div>
      Not inpired yet?
      <button
        class="generate"
        @click="generate"
      >
        Keep going
      </button>
    </div>
  </div>

</template>

<style lang="scss" scoped>

.wrapper {
  margin: 8px 16px;
}

.crystal-ball {
  font-size: 32px;
  vertical-align: middle;
}

.title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1.2px;
}

.generated-text {
  color: #000;
  font-size: 32px;
  padding: 16px;
  max-width: 500px;
  margin: 4em auto;
  min-height: 5em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  &__word {
  }
}

.byline {
  /* font-style: italic; */
  text-align: left;
  margin: 2em auto 0 auto;
  max-width: 500px;
  line-height: 1.4;
}

.generate {
  font-weight: 500;
  font-family: inherit;
  /* text-transform: uppercase; */
  font-size: 16px;
  padding: 8px 16px;
  border: 0;
  border-radius: 2px;
  color: #fff;
  background-color: #D657FF;
  border: 1px solid #A842C6;
  box-shadow: #4C0C4922 0px 5px 19px;
  transition: box-shadow linear 0.15s,
              transform ease-in-out 0.2s;

  &:hover, &:active {
    box-shadow: #4C0C4955 0px 5px 19px;
  }

  &:active {
    transform: scale(0.95);
    background-color: darken(#D657FF, 10%);
  }

  &:focus {
    outline: none;
  }
}

.word-transition-enter-active {
  transition: all 1s;
}

.word-transition-enter {
  opacity: 0;
}

.word-transition-leave-active {
  transition: none;
  opacity: 0;
  display: none;
}

.word-transition-enter-to {
  opacity: 1;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 15px;
    letter-spacing: 0;
  }

  .crystal-ball {
    font-size: 21px;
  }

  .generated-text {
    font-size: 28px;
    margin: 2em 0;
  }

  .generate {
    margin-top: 1em;
  }
}

@media screen and (max-width: 640px) {
  .generated-text {
    margin: 3em 0;
  }
}

</style>

<script>
/* eslint-disable no-underscore-dangle */

import { TextualHealing } from 'textual-healing';
import generatorData from '../assets/generatorData.json';

export default {
  data() {
    const generator = new TextualHealing(generatorData);
    return {
      generator,
      generatedText: generator.start(),
      seedValue: generator.generator._value,
    };
  },
  methods: {
    generate() {
      this.generatedText = this.generator.start();
      this.seedValue = this.generator.generator._value;
    },
  },
};
</script>
