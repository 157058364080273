<template>
  <div id="app">
    <Generator/>
  </div>
</template>

<script>
import Generator from './components/Generator.vue';

export default {
  name: 'App',
  components: {
    Generator,
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  font-family: 'Space Mono', monospace;
}
</style>
